import jQuery from 'jquery';
const $ = jQuery;

window.jQuery = $;
window.$ = $;

import 'cssuseragent';
import 'jquery.easing';


import ImgLazyLoad from './module/img-lazy-load';
import Router from './module/Router';
import SearchBox from './module/SearchBox';

if (navigator.platform == "iPad" ||        // -iOS12
  (navigator.platform == "MacIntel" &&   // iPadOS
  navigator.userAgent.indexOf("Safari") != -1 &&
  navigator.userAgent.indexOf("Chrome") == -1 &&
  navigator.standalone !== undefined)) {
  // document.querySelector("meta[name='viewport']").setAttribute("content", "width=959");
}


class SpMenu {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.$container = $('.SpMenu');
    this.$mask = $('.SpMenuHeight');
    this.isOpen = false;
  }
  open() {
    if(!this.isOpen) {
      this.isOpen = true;
      this.$mask.stop(false).css({
        'height': 0
      });
      this.$container.css({
        'display': 'block'
      });

      this.$mask.animate({'height': '100%'}, 300, 'easeOutExpo');
    }


  }
  close() {
    if(this.isOpen) {
      this.isOpen = false;

      this.$mask.animate({'height': '0%'}, 300, 'easeOutExpo', () => {
        this.$container.css({
          'display': 'none'
        });
      });
    }
  }
}

class MainApp {
  constructor() {
    this.router = new Router(this);
    this.searchBox = new SearchBox();

    $('.BtnSpSearch').on('click', () => {
      let str = $('#SpMenuInput').val();
      if(str.length > 0) {
        location.href = '/partner/' + encodeURI(str);
      }

    });

    this.inviewObject = [];

    $('body').on('click', () => {
      $('.js-inview').addClass('-show');
    });
  }


  isTouchDevice() {
    return ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;
  }
  startLoading() {
    $('.Loading').stop(false).css({
      'opacity': 1,
      'display': 'flex'
    });
  }
  stopLoading() {
    $('.Loading').stop(false).animate({'opacity':0}, 400, 'easeOutExpo', () => {
      $('.Loading').css('display', 'none');
    });
  }

  init() {
    this.$window              = $(window);

    this.viewMode             = 'pc';
    this.isTablet             = false;
    this.onLoadComplete       = true;

    this.imgLazyLoad          = new ImgLazyLoad(this);
    this.spMenu               = new SpMenu(this);

    if(this.isTouchDevice()) {
      $('html').addClass('is-touch-device');
      $('a').attr('data-touch', 'true');
      $('button').attr('data-touch', 'true');
    } else {
      $('html').addClass('is-mouse-device');
    }

    // シェアボタン
    /*$('.js-btn-share').on('click', (e) => {
      let shareText = document.title;
      if(!$('body').hasClass('home')) {
        shareText = shareText.replace('NIPPONIA - なつかしくて、あたらしい、日本の暮らしをつくる', 'NIPPONIA');
      }

      socialButton($(e.currentTarget).attr('data-type'), location.href, shareText);
      return false;
    });*/


    /*if(Number($('.check-res').width()) > 900) {
      this.viewMode = 'pc';
    } else {
      this.viewMode = 'sp';
    }*/

    /*if(!$('html').hasClass('ua-sp') && Number($('.check-tablet').width()) <= 960) {
      this.isTablet = true;
      $('.Header').addClass('Header--small');
      this.header.compact();
    }*/


    /*$(document).on('click', 'a', (e) => {
      if(String($(e.currentTarget).attr('href')).indexOf('/search') != -1 && $('body').hasClass('page-template-page-search')) {
        this.pageApp.clearConditions();
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
    });*/

    /*window.onpopstate = () => {
    };*/



    /*$(document).on('touchstart', 'a, button, .FeatureImgBlockItem, .GalleryGridItem, .SlideGalleryItem', (e) => {
      $(e.currentTarget).attr('data-touch', 'true');
    });
    $(document).on('mouseleave', 'a, button, .FeatureImgBlockItem, .GalleryGridItem, .SlideGalleryItem', (e) => {
      $(e.currentTarget).removeAttr('data-touch');
    });*/

    /*if(this.isTouchDevice()) {
      $('a').attr('data-touch', 'true');
      $('button').attr('data-touch', 'true');
    }*/

    /*$('.js-btn-pagetop').on('click', () => {
      $('html, body').animate({'scrollTop': 0}, 1000, 'easeInOutExpo');
      return false;
    });*/

    $('.NavListItem__a').on('click', (e) => {
      if(this.router.currentNamespace == 'home' && $(e.currentTarget).attr('href') == '/#service') {
        this.scrollPage($('.Section--service').offset()['top'] - $('.Header').height());
      }
    });

    $('.BtnToggleMenu').on('click', () => {
      if(this.spMenu.isOpen) {
        this.spMenu.close();
      } else {
        this.spMenu.open();
      }
    });

    this.scrollableElement = null;
    let firefox = navigator.userAgent.match(/Firefox\/([0-9]+)\./);
    if ('scrollingElement' in document) {
      this.scrollableElement = document.scrollingElement;
    } else if (/*@cc_on!@*/false || (!!window.MSInputMethodContext && !!document.documentMode)) {
      this.scrollableElement = document.documentElement;
    } else if (firefox && parseInt(firefox[1]) <= 47) {
      this.scrollableElement = document.documentElement;
    } else {
      this.scrollableElement = document.body;
    }




    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      })
      .on('scroll', ()=>{
        this.onWindowScroll();
      });
    this.onWindowResize();
  }

  setInviewObject() {
    this.inviewObject = [];
    $('.js-inview').each((i, e) => {
      this.inviewObject.push($(e));
    });
  }

  scrollPage(val, isCancelTransition = false) {
    if(isCancelTransition) {
      $(this.scrollableElement).stop(false).scrollTop(val);
    } else {
      $(this.scrollableElement).stop(false).animate(
        {'scrollTop': val},
        300,
        'easeOutExpo'
      );
    }
  }


  /*
  isTouchDevice() {
    var result = false;
    if (window.ontouchstart === null) {
      result = true;
    }
    return result;
  }
  */

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();
    if(this.scrollTop < 0) this.scrollTop = 0;

    if(this.pageApp != undefined) {
      this.pageApp.onWindowScroll(this.scrollTop);
    }

    if(this.scrollTop != this.prevScrollTop) {
      if(this.scrollTop > this.prevScrollTop) {
        this.scrollDiff = 1;
      } else {
        this.scrollDiff = -1;
      }
    }

    if(this.inviewObject.length > 0) {
      for(let i = 0; i < this.inviewObject.length; i++) {
        if(this.scrollTop + this.inviewPosition >= this.inviewObject[i].offset()['top']) {
          this.inviewObject[i].addClass('-show');
        }
      }
    }


    this.prevScrollTop = this.scrollTop;

    this.imgLazyLoad.update(this.scrollTop, this.windowHeight);
  }

  onWindowResize() {
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();

    this.inviewPosition = Math.floor(this.windowHeight * 0.7);


    if($('.BtnToggleMenu').css('display') == 'none') {
      this.viewMode = 'pc';
    } else {
      this.viewMode = 'sp';
    }

    this.fixGridTitleHeight();


    /*if(Number($('.check-res').width()) > 900) {
      this.viewMode = 'pc';
    } else {
      this.viewMode = 'sp';
    }
    if(!$('html').hasClass('ua-sp') && Number($('.check-tablet').width()) <= 960) {
      this.isTablet = true;
    } else {
      this.isTablet = false;
    }*/

    this.onWindowScroll();
  }

  fixGridTitleHeight() {
    if($('.js-fix-grid-title').length) {
      let gridSet = [];
      $('.js-fix-grid-title').find('.GridItem').each((i, e) => {
        if(gridSet.length == 0) {
          gridSet.push([$(e)]);
        } else {
          if(gridSet[gridSet.length - 1][gridSet[gridSet.length - 1].length - 1].offset()['top'] == $(e).offset()['top']) {
            gridSet[gridSet.length - 1].push($(e));
          } else {
            gridSet.push([$(e)]);
          }
        }
      });

      for(let i = 0; i < gridSet.length; i++) {
        let currentRow = gridSet[i];
        let titleHeight = 0;
        for(let t = 0; t < currentRow.length; t++) {
          if(titleHeight < currentRow[t].find('.GridItem__title').height()) {
            titleHeight = currentRow[t].find('.GridItem__title').height();
          }
        }

        for(let t = 0; t < currentRow.length; t++) {
          currentRow[t].find('.GridItem__title').height(titleHeight);
        }
      }
      gridSet = [];
      gridSet = null;
    }
  }

  onLoad() {
    $('.PreloadWebfont').css('display', 'none');
    this.onLoadComplete = true;

    setTimeout(() => {
      $('.Header').addClass('Header--fadein');

      setTimeout(() => {
        $('.Main').css('opacity', 1);

        /*
        if($('body').hasClass('home') && location.hash == '#service') {
          this.scrollPage($('.Section--service').offset()['top'] - $('.Header').height());
        }
        */
      }, 100);
    }, 100);
  }
}






let mainApp = new MainApp();


$('document').ready(() => {
  /*$.ajax({'type':'get','url': window.url_template + '/assets/images/symbol-defs.svg'}).done((data) => {
    let svg = $(data).find('svg');
    $('body').append(svg);
  });*/

  $(document).on('touchstart', 'a, button', (e) => {
    $(e.currentTarget).attr('data-touch', 'true');
  });

  $(document).on('mouseleave', 'a, button', (e) => {
    $(e.currentTarget).removeAttr('data-touch');
  });

  mainApp.init();

});

$(window).on('load', () => {
  mainApp.onLoad();
});


