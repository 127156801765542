/*globals $ */

class SearchBox {
  constructor() {
    this.isOpen = false;
    this.$container = $('.SearchBox');
    this.input = this.$container.find('input');
    this.$btn = this.$container.find('.BtnSearchSubmit');

    this.$btn.on('click', () => {
      this.submit();
    });
    this.input.keydown((e) => {
      if(this.isOpen && e.keyCode == 13) {
        this.submit();
      }
    });

    this.$container.on('mouseover', () => {
      clearInterval(this.mouseoutInterval);
      if(!this.isOpen) {
        this.open();
      }
    });
    this.$container.on('mouseout', () => {
      this.mouseoutInterval = setInterval(() => {
        clearInterval(this.mouseoutInterval);
        this.close();
      }, 500);
    });
  }
  submit() {
    if(this.isOpen) {
      let value = this.input[0].value;
      if(value.length > 1) {
        location.href = '/partner/' + encodeURI(value);
      }
    }

  }
  open() {
    if(!this.isOpen) {
      this.isOpen = true;
      this.$container.addClass('-open');
    }
  }
  close() {
    if(this.isOpen) {
      this.isOpen = false;
      this.$container.removeClass('-open');
      this.input[0].blur();
    }
  }
}

export default SearchBox;
