/*globals $ */
/*import SearchBox from './SearchBox';
import ProjectsMap from './ProjectsMap';
import StickyHeader from './StickyHeader';
import HomeSlideshow from './HomeSlideshow';*/

import PerfectScrollbar from 'perfect-scrollbar';

class PeopleImg {
  constructor(src) {
    this.src = src;
    this.image = new Image();
    this.image.src = this.src;
    this.onLoadComplete = false;

    this.loadInterval = setInterval(() => {
      if(this.image.width > 10) {
        this.onLoadComplete = true;
        clearInterval(this.loadInterval);
      }
    }, 1000 / 30);
  }
}

class PageHome {
  constructor(data, mainApp, isFirstLoading) {
    this.routingData      = data;
    this.mainApp          = mainApp;

    this.initializeDelay  = 0;
    this.fadeinDelay      = 0;
    if(isFirstLoading) {
      this.initializeDelay  = 400;
      this.fadeinDelay      = 200;
    }

    this.waitInterval = setInterval(() => {
      if(this.mainApp.onLoadComplete) {
        clearInterval(this.waitInterval);
        this.init();
      }
    }, 1000 / 30);
  }

  shuffleArray([...array]) {
    for (let i = array.length - 1; i >= 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
  }

  init() {


    let imgObjSet = [];
    $('.HeroHome__img').each((i, e) => {
      imgObjSet.push(new PeopleImg($(e).attr('src')));
    });

    this.loadInterval = setInterval(() => {
      let loadCount = 0;

      for(let i = 0; i < imgObjSet.length; i++) {
        if(imgObjSet[i].onLoadComplete) {
          loadCount++;
        }
      }

      if(loadCount == 9) {
        clearInterval(this.loadInterval);
        setTimeout(() => {
          this.fadein();
        }, 100);
      }

    }, 1000 / 30);


  }

  fadeinOld() {
    let delaySet = [];
    let maxDelay = 0;
    for(let i = 0; i < 9; i++) {
      delaySet.push(this.initializeDelay + i * 60);
      maxDelay = this.initializeDelay + i * 60;
    }
    delaySet = this.shuffleArray(delaySet);

    $('.HeroHome__img').each((i, e) => {
      setTimeout(() => {
        $(e).addClass('HeroHome__img--fadein');
      }, delaySet[i]);
    });

    setTimeout(() => {
      this.tagCloud = new TagCloud(this.mainApp);

      this.ps = new PerfectScrollbar('.HorizontalScroller', {
        'suppressScrollY': true
      });
    }, maxDelay + 150);
  }

  fadein() {



    setTimeout(() => {
      this.tagCloud = new TagCloud(this);

      this.ps = new PerfectScrollbar('.HorizontalScroller', {
        'suppressScrollY': true
      });

      setTimeout(() => {
        let delaySet = [];
        let maxDelay = 0;
        for(let i = 0; i < 9; i++) {
          delaySet.push(this.initializeDelay + i * 60);
          maxDelay = this.initializeDelay + i * 60;
        }
        delaySet = this.shuffleArray(delaySet);

        this.mainApp.stopLoading();

        $('.HeroHome__img').each((i, e) => {
          setTimeout(() => {
            $(e).addClass('HeroHome__img--fadein');
          }, delaySet[i]);
        });

        setTimeout(() => {
          $('.Section--tag').css('opacity', 1);
          $('.Section--topics').css('opacity', 1);

          if(this.routingData.next.url.hash == 'service') {
            this.mainApp.scrollPage($('.Section--service').offset()['top'] - $('.Header').height());
          }

          setTimeout(() => {
            this.tagCloud.afterInit();

          }, 200);
        }, maxDelay);

      }, 50);
    }, this.fadeinDelay);

  }

  onWindowScroll() {
  }

  onWindowResize() {
  }

  onLoad() {
  }

  destructor() {
    this.ps.destroy();
    this.ps = null;
  }
}

class TagCloud {
  constructor(pageApp) {
    this.pageApp = pageApp;
    this.mainApp = this.pageApp.mainApp;
    this.$container = $('.TagCloud');
    this.tags = [];

    this.containerShowLimit = $('.CommonWidth__inner').width() + 200;


    $('#TagCloud').find('.Tag').each((i, e) => {
      this.tags.push($(e));
    });

    this.baseTagHeight = this.$container.children('.Tag:first-child').height();
    this.totalWidth = 0;

    this.bottomMargin = 9;

    if(this.mainApp.viewMode == 'sp') {
      this.bottomMargin = 3;
    }
    this.initializedIndex = 0;
    this.tagLength = this.tags.length;
    this.stopRendering = false;
    this.overCount = 0;

    for(let i = 0; i < this.tagLength; i++) {
      let top = i % 4 * (this.baseTagHeight + this.bottomMargin);
      let left = 0;
      if(Math.floor(i / 4) > 0) {
        // left = Math.floor(this.tags[i - 4].position()['left'] + this.tags[i - 4].width()) + 27;
        left = Math.floor(this.tags[i - 4].position()['left'] + this.tags[i - 4].width()) + 0;
      }


      if(left > this.containerShowLimit) {
        this.overCount++;
        if(this.overCount >= 4) {
          this.stopRendering = true;
        }
      }

      if(!this.stopRendering) {
        this.tags[i].css({
          'top': top,
          'left': left
        });
        this.initializedIndex = i;
      }

      let tagWidth = Math.floor(this.tags[i].width());
      if(this.totalWidth < left + tagWidth) {
        this.totalWidth = left + tagWidth;
      }
    }

    this.$container.css({
      'width': this.totalWidth,
      'height': this.baseTagHeight * 4 + this.bottomMargin * 3
    });
  }

  afterInit() {
    for(let i = this.initializedIndex - 1; i < this.tagLength; i++) {
      let top = i % 4 * (this.baseTagHeight + this.bottomMargin);
      let left = 0;
      if(Math.floor(i / 4) > 0) {
        // left = Math.floor(this.tags[i - 4].position()['left'] + this.tags[i - 4].width()) + 27;
        left = Math.floor(this.tags[i - 4].position()['left'] + this.tags[i - 4].width()) + 0;
      }

      this.tags[i].css({
        'top': top,
        'left': left
      });

      let tagWidth = Math.floor(this.tags[i].width());
      if(this.totalWidth < left + tagWidth) {
        this.totalWidth = left + tagWidth;
      }
    }

    this.$container.css({
      'width': this.totalWidth,
      'height': this.baseTagHeight * 4 + this.bottomMargin * 3
    });

    setTimeout(() => {
      this.pageApp.ps.update();
    }, 100);
  }

  destroy() {
    this.$container = null;
    this.tags = [];
    this.tags = null;
  }
}

export default PageHome;
