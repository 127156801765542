/*globals $,gtag */

import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";


import barba from '@barba/core';

import PageHome from './PageHome';

class Router {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.pageApp = undefined;
    this.currentNamespace = '';

    barba.init({
      debug: false,
      transitions: [{
        name: 'default-transition',
        beforeOnce : () => {
          // console.log('beforeOnce', data);
        },
        once : (data) => {
          // console.log('once', data);
          this.currentNamespace = data['next']['namespace'];
        },
        afterOnce : (data) => {
          this.setPageApp(data, true);
        },
        before: async (data) => {

          if($(data['trigger']).hasClass('NavListItem__a')) {
            $(data['trigger']).parent().addClass('NavListItem--current');
          }

          if(this.mainApp.spMenu.isOpen) {
            this.mainApp.spMenu.close();
          }

          let waitDuration = 510;
          $('.Transition').addClass('Transition--fadein');

          await new Promise((resolve) => {
            return setTimeout(resolve, waitDuration);
          });
        },
        leave : () => {
          // $('.wrapper').css('height', parseInt($('div[data-barba="container"]').outerHeight(true)));


          $('.Transition').addClass('Transition--loading');



          if(this.pageApp != undefined) {
            this.pageApp.destructor();
            this.pageApp = null;
            this.pageApp = undefined;
          }

          // this.mainApp.scrollPage(0, true);
        },
        enter : (data) => {
          console.log('enter', data);
          this.currentNamespace = data['next']['namespace'];

          /*if(data['next']['namespace'] == 'home') {
            this.mainApp.scrollPage(0);
          } else {
            this.mainApp.scrollPage(0, true);
          }*/
          console.log('enter next namespace', data['next']['namespace']);


          this.setPageApp(data);

          this.mainApp.imgLazyLoad.init();

          $('.NavListItem').each((i, e) => {
            let navNamespace = $(e).attr('data-namespace');
            if(
              $(e).attr('data-namespace') != data['next']['namespace']
              && data['next']['namespace'].indexOf(navNamespace) == -1)
            {
              $(e).removeClass('NavListItem--current');
            }
          });


          // $('.NavListItem').removeClass('NavListItem--current');
          if(data['next']['namespace'] != 'home') {
            if(data['next']['namespace'] == 'topics') {
              $('.NavListItem[data-namespace="topics"]').addClass('NavListItem--current');
            } else if(data['next']['namespace'] == 'about') {
              $('.NavListItem[data-namespace="about"]').addClass('NavListItem--current');
            } else if(data['next']['namespace'] == 'partner' || data['next']['namespace'] == 'partner-single') {
              $('.NavListItem[data-namespace="partner"]').addClass('NavListItem--current');
            } else if(data['next']['namespace'] == 'member' || data['next']['namespace'] == 'member-single') {
              $('.NavListItem[data-namespace="member"]').addClass('NavListItem--current');
            } else if(data['next']['namespace'] == 'interview' || data['next']['namespace'] == 'interview-single') {
              $('.NavListItem[data-namespace="interview"]').addClass('NavListItem--current');
            } else if(data['next']['namespace'] == 'works' || data['next']['namespace'] == 'works-single') {
              $('.NavListItem[data-namespace="works"]').addClass('NavListItem--current');
            }
          }



          setTimeout(() => {
            $('.Transition').addClass('Transition--fadeout');
            setTimeout(() => {
              $('.Transition').attr('class', 'Transition');

              /*
              if(data['next']['namespace'] == 'home' && data['next']['url']['hash'] != undefined) {
                if(data['next']['url']['hash'] == 'service') {
                  this.mainApp.scrollPage($('.Section--service').offset()['top'] - $('.Header').height());
                }
              }
              */
            }, 410);

            if(data['next']['namespace'] == 'contact') {
              this.initContactFormScript();
            }

            this.mainApp.onWindowResize();
          }, 100);

          let nextTitle = $(data.next.html.match(/<title(?: .+?)?>.*?<\/title>/g)[0]).text();

          if(!$('body').hasClass('logged-in')) {
            gtag('config', 'UA-171038422-1', {
              'page_title' : nextTitle,
              'page_path': data.next.url.path
            });
          }

        }
      }]
    });

    barba.hooks.enter(() => {
      window.scrollTo(0, 0);
    });
  }

  setPageApp(data, isFirstLoading = false) {

    if(data['next']['namespace'] == 'home') {
      this.mainApp.startLoading();
    }

    if(data['next']['namespace'] == 'home') {
      this.pageApp = new PageHome(data, this.mainApp, isFirstLoading);
    } else {
      this.mainApp.stopLoading();
    }

    if(data['next']['namespace'] == 'contact') {
      $('.ConversionArea').css('display', 'none');
    } else {
      $('.ConversionArea').css('display', 'block');
    }

    this.mainApp.setInviewObject();

    if(this.mainApp.isTouchDevice()) {
      $('a').attr('data-touch', 'true');
      $('button').attr('data-touch', 'true');
    }
  }


  initContactFormScript() {
    $.get("/cms/wp-content/plugins/contact-form-7/includes/js/scripts.js?ver=5.1.9");
    $.get("/cms/wp-content/plugins/contact-form-7-add-confirm/includes/js/scripts.js?ver=5.1");
  }
}

export default Router;
